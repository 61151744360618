import React from 'react';

const kinds = {
	info: '#5352ED',
	positive: '#2ED573',
	negative: '#FF4757',
	warning: '#FFA502'
};

export const Alert = ({
	children,
	kind = 'info',
	...rest
}: {
	children: React.ReactElement;
	kind: string;
	rest: any;
}): React.ReactElement => (
	<div
		style={{
			padding: 20,
			borderRadius: 3,
			color: 'white',
			background: kinds[kind]
		}}
		{...rest}
	>
		{children}
	</div>
);
